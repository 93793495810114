import FiveGridLine_ZigzagLineSkinComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/ZigzagLineSkin/ZigzagLineSkin.skin';


const FiveGridLine_ZigzagLineSkin = {
  component: FiveGridLine_ZigzagLineSkinComponent
};


export const components = {
  ['FiveGridLine_ZigzagLineSkin']: FiveGridLine_ZigzagLineSkin
};

